
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import HabilidadTab from './solicitudServicios.vue';
 
import EstandarTab from './solicitudcambioestatus.vue';
//import FundamentosTab from './solicituddocumentacion.vue';
//import HistTab from './solicitudhistorial.vue';
export default {
  components: {
    DxTabPanel, DxItem, HabilidadTab, EstandarTab//, FundamentosTab  
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
